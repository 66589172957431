import { VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin((nuxtApp) => {
  const {public: runtimeConfig } = useRuntimeConfig()

  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: runtimeConfig.recaptcha_key,
    loaderOptions: {
      autoHideBadge: true,
    }
  })
})
