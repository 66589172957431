import plugin_vue3_A0OWXRrUgq from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import locomotiveScroll_QQZjEf0HiW from "/usr/src/nuxt-app/plugins/locomotiveScroll.js";
import lazyLoading_CKCi3nbFww from "/usr/src/nuxt-app/plugins/lazyLoading.js";
import socialShare_gA6PfhsR4E from "/usr/src/nuxt-app/plugins/socialShare.js";
import inject_yVQjWpgE2k from "/usr/src/nuxt-app/plugins/inject.js";
import recaptcha_W4US4xmBV6 from "/usr/src/nuxt-app/plugins/recaptcha.js";
import sentry_client_GoGQuZo4Et from "/usr/src/nuxt-app/plugins/sentry.client.js";
import vue_gtm_client_Cm24Dsc5BN from "/usr/src/nuxt-app/plugins/vue-gtm.client.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  locomotiveScroll_QQZjEf0HiW,
  lazyLoading_CKCi3nbFww,
  socialShare_gA6PfhsR4E,
  inject_yVQjWpgE2k,
  recaptcha_W4US4xmBV6,
  sentry_client_GoGQuZo4Et,
  vue_gtm_client_Cm24Dsc5BN
]