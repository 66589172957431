export default (
  lazyImages,
  root_selector,
  rootMargin = '0px',
  threshold = 0,
  lazy_class = 'lazy__image' ) => {
  const config = {
    root: document.querySelector(root_selector),
    rootMargin,
    threshold }

  if (!lazyImages.length) return

  if ('IntersectionObserver' in window) {
    let lazyImageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let lazyImage = entry.target
          if (lazyImage.dataset.src) {
            lazyImage.src = lazyImage.dataset.src
          }
          if (lazyImage.dataset.srcset) {
            lazyImage.srcset = lazyImage.dataset.srcset
          }
          lazyImage.classList.remove(lazy_class)
          lazyImageObserver.unobserve(lazyImage)
        }
      }, config)
    })

    lazyImages.forEach((lazyImage) => lazyImageObserver.observe(lazyImage))
  } else {
    lazyImages.forEach((image) => {
      image.src = image.dataset.src
      image.srcset = image.dataset.srcset
      image.classList.remove(lazy_class)
    })
  }
}
