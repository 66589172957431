import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

export default defineNuxtPlugin((nuxtApp) => {
  Sentry.init({
    dsn: "https://76d167e58b8a404dab698f7a03cc3ec1@sentry.wisesoft.tech//19",
    integrations: [new BrowserTracing()],
    allowUrls: [/https?:\/\/nekidaem\.ru/],
    denyUrls: [/localhost:3000/],
    tracesSampleRate: 0.5,
  })
})
