import { default as aboutqNk3HSzUMDMeta } from "/usr/src/nuxt-app/pages/about.vue?macro=true";
import { default as _91slug_93wbjkj0xi4EMeta } from "/usr/src/nuxt-app/pages/blog/[slug].vue?macro=true";
import { default as indexJBbsGA1qIvMeta } from "/usr/src/nuxt-app/pages/blog/index.vue?macro=true";
import { default as contacts2ZxaGfWR2JMeta } from "/usr/src/nuxt-app/pages/contacts.vue?macro=true";
import { default as fluttervWEoIlnXfsMeta } from "/usr/src/nuxt-app/pages/flutter.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as methodologyTRNG96mmhiMeta } from "/usr/src/nuxt-app/pages/methodology.vue?macro=true";
import { default as _91slug_93MdLNC5BEbiMeta } from "/usr/src/nuxt-app/pages/portfolio/[slug].vue?macro=true";
import { default as indexRV5pcEV1KpMeta } from "/usr/src/nuxt-app/pages/portfolio/index.vue?macro=true";
import { default as lflruut4NbvrXGFMeta } from "/usr/src/nuxt-app/pages/portfolio/lflru.vue?macro=true";
import { default as oncona9fOdHaq2jOMeta } from "/usr/src/nuxt-app/pages/portfolio/oncona.vue?macro=true";
import { default as rebrending_45svoego_45sajtabDKhm7mbhYMeta } from "/usr/src/nuxt-app/pages/portfolio/rebrending-svoego-sajta.vue?macro=true";
import { default as trstatFvA1as7ytlMeta } from "/usr/src/nuxt-app/pages/portfolio/trstat.vue?macro=true";
import { default as projectL3JUsmtjLpMeta } from "/usr/src/nuxt-app/pages/project.vue?macro=true";
import { default as technologyJx98MQ24XUMeta } from "/usr/src/nuxt-app/pages/technology.vue?macro=true";
import { default as _91slug_93JBvvfon28oMeta } from "/usr/src/nuxt-app/pages/uslugi/[slug].vue?macro=true";
import { default as indexMvHcV5SudzMeta } from "/usr/src/nuxt-app/pages/uslugi/index.vue?macro=true";
import { default as _91slug_930fH0LdZK1kMeta } from "/usr/src/nuxt-app/pages/vacancy/[slug].vue?macro=true";
import { default as indexv0ZsmeN573Meta } from "/usr/src/nuxt-app/pages/vacancy/index.vue?macro=true";
export default [
  {
    name: aboutqNk3HSzUMDMeta?.name ?? "about",
    path: aboutqNk3HSzUMDMeta?.path ?? "/about",
    meta: aboutqNk3HSzUMDMeta || {},
    alias: aboutqNk3HSzUMDMeta?.alias || [],
    redirect: aboutqNk3HSzUMDMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wbjkj0xi4EMeta?.name ?? "blog-slug",
    path: _91slug_93wbjkj0xi4EMeta?.path ?? "/blog/:slug",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    alias: _91slug_93wbjkj0xi4EMeta?.alias || [],
    redirect: _91slug_93wbjkj0xi4EMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexJBbsGA1qIvMeta?.name ?? "blog",
    path: indexJBbsGA1qIvMeta?.path ?? "/blog",
    meta: indexJBbsGA1qIvMeta || {},
    alias: indexJBbsGA1qIvMeta?.alias || [],
    redirect: indexJBbsGA1qIvMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contacts2ZxaGfWR2JMeta?.name ?? "contacts",
    path: contacts2ZxaGfWR2JMeta?.path ?? "/contacts",
    meta: contacts2ZxaGfWR2JMeta || {},
    alias: contacts2ZxaGfWR2JMeta?.alias || [],
    redirect: contacts2ZxaGfWR2JMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: fluttervWEoIlnXfsMeta?.name ?? "flutter",
    path: fluttervWEoIlnXfsMeta?.path ?? "/flutter",
    meta: fluttervWEoIlnXfsMeta || {},
    alias: fluttervWEoIlnXfsMeta?.alias || [],
    redirect: fluttervWEoIlnXfsMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/flutter.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: methodologyTRNG96mmhiMeta?.name ?? "methodology",
    path: methodologyTRNG96mmhiMeta?.path ?? "/methodology",
    meta: methodologyTRNG96mmhiMeta || {},
    alias: methodologyTRNG96mmhiMeta?.alias || [],
    redirect: methodologyTRNG96mmhiMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/methodology.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MdLNC5BEbiMeta?.name ?? "portfolio-slug",
    path: _91slug_93MdLNC5BEbiMeta?.path ?? "/portfolio/:slug",
    meta: _91slug_93MdLNC5BEbiMeta || {},
    alias: _91slug_93MdLNC5BEbiMeta?.alias || [],
    redirect: _91slug_93MdLNC5BEbiMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/portfolio/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexRV5pcEV1KpMeta?.name ?? "portfolio",
    path: indexRV5pcEV1KpMeta?.path ?? "/portfolio",
    meta: indexRV5pcEV1KpMeta || {},
    alias: indexRV5pcEV1KpMeta?.alias || [],
    redirect: indexRV5pcEV1KpMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/portfolio/index.vue").then(m => m.default || m)
  },
  {
    name: lflruut4NbvrXGFMeta?.name ?? "portfolio-lflru",
    path: lflruut4NbvrXGFMeta?.path ?? "/portfolio/lflru",
    meta: lflruut4NbvrXGFMeta || {},
    alias: lflruut4NbvrXGFMeta?.alias || [],
    redirect: lflruut4NbvrXGFMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/portfolio/lflru.vue").then(m => m.default || m)
  },
  {
    name: oncona9fOdHaq2jOMeta?.name ?? "portfolio-oncona",
    path: oncona9fOdHaq2jOMeta?.path ?? "/portfolio/oncona",
    meta: oncona9fOdHaq2jOMeta || {},
    alias: oncona9fOdHaq2jOMeta?.alias || [],
    redirect: oncona9fOdHaq2jOMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/portfolio/oncona.vue").then(m => m.default || m)
  },
  {
    name: rebrending_45svoego_45sajtabDKhm7mbhYMeta?.name ?? "portfolio-rebrending-svoego-sajta",
    path: rebrending_45svoego_45sajtabDKhm7mbhYMeta?.path ?? "/portfolio/rebrending-svoego-sajta",
    meta: rebrending_45svoego_45sajtabDKhm7mbhYMeta || {},
    alias: rebrending_45svoego_45sajtabDKhm7mbhYMeta?.alias || [],
    redirect: rebrending_45svoego_45sajtabDKhm7mbhYMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/portfolio/rebrending-svoego-sajta.vue").then(m => m.default || m)
  },
  {
    name: trstatFvA1as7ytlMeta?.name ?? "portfolio-trstat",
    path: trstatFvA1as7ytlMeta?.path ?? "/portfolio/trstat",
    meta: trstatFvA1as7ytlMeta || {},
    alias: trstatFvA1as7ytlMeta?.alias || [],
    redirect: trstatFvA1as7ytlMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/portfolio/trstat.vue").then(m => m.default || m)
  },
  {
    name: projectL3JUsmtjLpMeta?.name ?? "project",
    path: projectL3JUsmtjLpMeta?.path ?? "/project",
    meta: projectL3JUsmtjLpMeta || {},
    alias: projectL3JUsmtjLpMeta?.alias || [],
    redirect: projectL3JUsmtjLpMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/project.vue").then(m => m.default || m)
  },
  {
    name: technologyJx98MQ24XUMeta?.name ?? "technology",
    path: technologyJx98MQ24XUMeta?.path ?? "/technology",
    meta: technologyJx98MQ24XUMeta || {},
    alias: technologyJx98MQ24XUMeta?.alias || [],
    redirect: technologyJx98MQ24XUMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/technology.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JBvvfon28oMeta?.name ?? "uslugi-slug",
    path: _91slug_93JBvvfon28oMeta?.path ?? "/uslugi/:slug",
    meta: _91slug_93JBvvfon28oMeta || {},
    alias: _91slug_93JBvvfon28oMeta?.alias || [],
    redirect: _91slug_93JBvvfon28oMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/uslugi/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMvHcV5SudzMeta?.name ?? "uslugi",
    path: indexMvHcV5SudzMeta?.path ?? "/uslugi",
    meta: indexMvHcV5SudzMeta || {},
    alias: indexMvHcV5SudzMeta?.alias || [],
    redirect: indexMvHcV5SudzMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/uslugi/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930fH0LdZK1kMeta?.name ?? "vacancy-slug",
    path: _91slug_930fH0LdZK1kMeta?.path ?? "/vacancy/:slug",
    meta: _91slug_930fH0LdZK1kMeta || {},
    alias: _91slug_930fH0LdZK1kMeta?.alias || [],
    redirect: _91slug_930fH0LdZK1kMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/vacancy/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexv0ZsmeN573Meta?.name ?? "vacancy",
    path: indexv0ZsmeN573Meta?.path ?? "/vacancy",
    meta: indexv0ZsmeN573Meta || {},
    alias: indexv0ZsmeN573Meta?.alias || [],
    redirect: indexv0ZsmeN573Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/vacancy/index.vue").then(m => m.default || m)
  }
]