export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon/favicon.ico"},{"rel":"icon","type":"image/png","href":"/favicon/android-chrome-192x192.png"},{"rel":"icon","type":"image/png","href":"/favicon/android-chrome-512x512.png"},{"rel":"icon","type":"image/png","href":"/favicon/favicon-16x16.png"},{"rel":"icon","type":"image/png","href":"/favicon/favicon-32x32.png"}],"style":[],"script":[{"children":"(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n        (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n        ym(27362975, \"init\", {\n           clickmap:true,\n           trackLinks:true,\n           accurateTrackBounce:true,\n           webvisor:true,\n           ecommerce:\"dataLayer\"\n        });","type":"text/javascript"}],"noscript":[{"children":"<div><img src=\"https://mc.yandex.ru/watch/27362975\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div>"}],"htmlAttrs":{"lang":"ru"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"